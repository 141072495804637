import { CToast, CToastBody, CToastClose, CToastHeader } from "@coreui/bootstrap-react";
import React from "react";


const BackendErrorToast = (
    <CToast title="FTTH Eligibilité" autohide={false} visible={true}>
        <CToastHeader close>
            <svg
                className="rounded me-2"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
            >
                <rect width="100%" height="100%" fill="#C62828"></rect>
            </svg>
            <strong className="me-auto">Erreur système</strong>
            <CToastClose className="me-2 m-auto" />
        </CToastHeader>
        <CToastBody>Veuillez verifier votre connexion. Rafraichir la page et réessayer.</CToastBody>
    </CToast>

    
);

const NotEligibleErrorToast = (
    <CToast autohide={false} visible={true} className="align-items-center">
        <div className="d-flex">
            <CToastBody>Hello, world! This is a toast message.</CToastBody>
            <CToastClose className="me-2 m-auto" />
        </div>
    </CToast>
);


export {
    BackendErrorToast,
    NotEligibleErrorToast,
};