class SendEmailService {
  async sendmail(lastname, address, phone, email, status, latitude, longitude) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/registration`;

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lastname: lastname,
          address: address,
          phone: phone,
          email: email,
          status: status,
          latitude: Number(latitude).toFixed(7),
          longitude: Number(longitude).toFixed(7),
        }),
      });

      if (!response.ok) {
        let errorMessage;
        const responseData = await response.json();
        if (responseData && responseData.error) {
          errorMessage = `Failed to send email, HTTP status ${response.status} : ${responseData.error}`;
        }
        throw new Error(errorMessage);
      }

      const responseData = await response.json();
      // console.log(responseData); 

      return responseData;
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  }
}

export default new SendEmailService();
